

















import { defineComponent } from '@vue/composition-api'
import { ContactSection } from '@/inc/types'

import ContactGrid from '@/components/contact/Grid.vue'

export default defineComponent({
  name: 'ContactSection',
  props: {
    content: {
      type: Object as () => ContactSection,
      Default: {} as ContactSection,
    },
  },

  components: {
    ContactGrid,
  },
})





































/* eslint-disable quotes */
import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

// ComponentsImport
import ContactSection from '@/components/contact/Section.vue'

export default defineComponent({
  name: 'Contact',

  components: {
    ContactSection,
  },

  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})

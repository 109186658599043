

























































































import type { ContactCard } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'ContactCard',
  props: {
    content: {
      type: Object as PropType<ContactCard>,
      required: true,
    },
  },
})
